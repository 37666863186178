import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import axios from 'axios';

interface Message {
  id: number;
  role: string;
  content: string;
  loading?: boolean;
}

function App() {

  const [content, setContent] = useState<string>("");
  const [index, setIndex] = useState<number>(-1);
  const [messages, setMessages] = useState<Array<Message>>([]);
  const getTimeString = (): string => {
    const date = new Date();
    let hours: any = date.getHours();
    let minutes: any = date.getMinutes();
    let ampm: string = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  }
  const onsubmit = (e: any): void => {
    const msgs: Array<Message> = structuredClone(messages);
    msgs.push({
      id: index + 1,
      content: content,
      role:"user",
      loading: false
    } as Message);
    const serverId: number = index + 2;
    const serverReply: Message = {
      id: serverId,
      content: 'loading...',
      role: 'assistant',
      loading: false
    };
    msgs.push(serverReply);
    setIndex(index + 2);
    setMessages(msgs);
    axios.post('/chat', {
      "content": content
    }).then(r => {
      if(serverId >= 0) {
        const messagesArray = structuredClone(msgs);
        messagesArray[serverId].content = r.data.choices[0]['message']['content'];
        setMessages(messagesArray);
       }
       setContent("");
    }).catch(e => {
      console.log(e);
      
    });
  }

  const keyPress = (e: any) => {
    const key = e.keyCode;
    if(key === 13) {
      onsubmit(e);
    }
  }

  return (
    <div className='container container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <h5>Chatbot with OpenAI</h5>
        </div>
      </div>
      <div className='row'>

        <div className='col-lg-12 col-md-12 col-12'>
          <div className='row'>
            <div className='col-12'>
              {/* Content Area  */}
              <div className="position-relative">
                <div className="chat-messages p-4">
                  {
                    messages.map(m => {
                      return (
                        <>
                          <div className={`chat-message-${m.role === "assistant" ? 'left' : 'right'} pb-4`}>
                            <div>
                                <div className="text-muted small text-nowrap mt-2">{getTimeString()}</div>
                            </div>
                            <div className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                              <div className="font-weight-bold mb-1">{m.role === 'assistant' ? 'Matrix Chatbot' : 'You'}</div>
                              {m.content}
                            </div>
                          </div>
                        </>
                      )
                    })
                  }
                </div>
              </div>
              <div className="flex-grow-0 py-3 px-4 border-top">
                <div className="input-group">
                  <input type="text" className="form-control" placeholder="Type your message" value={content} onChange={e => setContent(e.target.value)} onKeyDown={e => keyPress(e)}/>
                    <button className="btn btn-primary" onClick={(e: any) => onsubmit(e)}>Send</button>
                </div>
              </div>
            </div>
            <div className='col-12'>
              {/* Content Send Button  */}

            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default App;
